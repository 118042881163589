function openMenu () {
  const btn = document.querySelector('[data-nav-toggle]'),
        collapseMenu = document.querySelector('[data-nav-collapse]');
  if (btn) {
    btn.addEventListener('click', () => {

      collapseMenu.classList.remove('d-none');
        setTimeout(() => {
          collapseMenu.classList.add('--collapse-enter');
        }, 1);
      btn.setAttribute('aria-expanded', 'true');
    });
  }
}

function closeMenu () {
  const btn = document.querySelector('[data-collapse-close]'),
        btnToggle = document.querySelector('[data-nav-toggle]'),
        collapseMenu = document.querySelector('[data-nav-collapse]');

  if (btn) {
    btn.addEventListener('click', () => {
      collapseMenu.classList.add('--collapse-leave');
      setTimeout(() => {
        collapseMenu.classList.remove('--collapse-enter');
      }, 1);
      setTimeout(() => {
        collapseMenu.classList.remove('--collapse-leave');
        collapseMenu.classList.add('d-none');
      }, 201);
      btnToggle.setAttribute('aria-expanded', 'false');
      closeSubMenus();
    });
  }
}

function openSubMenu () {
  const subMenus = document.querySelectorAll('[data-sub-menu]');

  if (subMenus.length > 0) {
    for (let i = 0; i < subMenus.length; i++) {
      let subMenu = subMenus[i],
          btn = subMenu.querySelector('[data-sub-menu-button]');

      if (btn) {
        btn.addEventListener('click', () => {
          let attribute = btn.getAttribute('aria-expanded', 'false');
          subMenus.forEach(el => el.classList.remove('--is-open'));

          if (attribute === 'false') {
            subMenu.classList.remove('--is-hidden');
            setTimeout(() => {
              subMenu.classList.add('--is-open');
            },1)
            btn.setAttribute('aria-expanded', 'true');
          } else {
            subMenu.classList.remove('--is-open');
            setTimeout(() => {
              subMenu.classList.add('--is-hidden');
            },301)
            btn.setAttribute('aria-expanded', 'false');
          }
        });
      }
    }
  }
}

function closeSubMenus () {
  const subMenus = document.querySelectorAll('[data-sub-menu]');

  subMenus.forEach(el => el.classList.remove('--is-open'));
  setTimeout(() => {
    subMenus.forEach(el => el.classList.remove('--is-hidden'));
  },301)
  subMenus.forEach(el => el.querySelector('[data-sub-menu-button]').setAttribute('aria-expanded', 'false'));
}

function clickOutsideSubMenu () {
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('navigation__nav-btn')) {
      closeSubMenus();
    }
  });
}

function addLanguageEvent () {
  const languageUrls = document.querySelectorAll('[data-redirect-by-language]');


  for (let i = 0; i < languageUrls.length; i++) {
    const item = languageUrls[i];
    item.onclick = function() {
      window.location.href = this.getAttribute('data-url');
    }
  }
}

openMenu();
closeMenu();
clickOutsideSubMenu();
addLanguageEvent();

export {
  openSubMenu
};
